// features/matchSimulation/formatTeamDataMiddleware.js

import {
  initiateMatchSimulation,
  fetchCompleteMatchData,
  setError,
} from "@/features/matchSimulation/matchSimulationSlice";

const DEFAULT_TACTICS = {
  formation: "4-3-3",
  playingStyle: "Balanced",
  offensiveStyle: { playersInBox: 2, style: "Balanced offense" },
  defensiveStyle: { depth: 4, style: "Balanced defense" },
};

const formatTeamDataMiddleware = (store) => (next) => (action) => {
  if (action.type === "matchSimulation/initiateSimulation") {
    const state = store.getState();
    const { teamSelection, tactics } = state.team || {};
    const { rolesData = [] } = state.roles || {};
    const teamA = teamSelection?.local?.team;
    const teamB = teamSelection?.opponent?.team;

    if (!teamA || !teamB) {
      console.error("Middleware: Missing team data in state");
      store.dispatch(setError("Formatted team data is incomplete"));
      return next(action);
    }

    const formatPlayerSkills = (skills, position) => {
      if (!skills) return {};
      const skillArray = skills.split("|").map(Number);
      if (skillArray.length < 5) return {};
      const [skill1, skill2, skill3, skill4, skill5] = skillArray;
      return position === "POR"
        ? {
            reflexes: skill1 || 0,
            diving: skill2 || 0,
            handling: skill3 || 0,
            kicking: skill4 || 0,
            speed: skill5 || 0,
          }
        : {
            pace: skill1 || 0,
            defense: skill2 || 0,
            dribbling: skill3 || 0,
            shooting: skill4 || 0,
            passing: skill5 || 0,
          };
    };

    const mapRolesToPlayers = (roles, players, isTeamB = false) => {
      if ((!Array.isArray(roles) && !isTeamB) || !Array.isArray(players))
        return {};

      const roleMappings = {
        captain: "team-captain",
        left_corner: "left-corner-taker",
        right_corner: "right-corner-taker",
        left_free_kick: "left-free-kick-taker",
        right_free_kick: "right-free-kick-taker",
        long_free_kick: "long-distance-free-kick-taker",
        penalty_kick: "penalty-kick-taker",
      };
      const mappedRoles = {};

      if (isTeamB) {
        if (typeof roles !== "object") return mappedRoles;
        Object.entries(roles).forEach(([roleKey, playerNumber]) => {
          if (playerNumber == null) return;
          const player = players.find((p) => p.playerNumber === playerNumber);
          if (player && roleMappings[roleKey]) {
            mappedRoles[roleMappings[roleKey]] =
              player.playerName || "Unknown Player";
          }
        });
      } else {
        roles.forEach(({ roleKey, playerNumber }) => {
          if (playerNumber == null) return;
          const player = players.find((p) => p.playerNumber === playerNumber);
          if (player && roleMappings[roleKey]) {
            mappedRoles[roleMappings[roleKey]] =
              player.playerName || "Unknown Player";
          }
        });
      }

      return mappedRoles;
    };

    const formatTeam = (team, isTeamB = false) => {
      if (!team) return null;
      const formattedTeam = {
        teamName: team.fullName || team.teamName || "Unknown Team",
        players:
          team.players?.map((player) => ({
            name: player.playerName || "Unknown Player",
            position: player.position || "ND",
            number: player.playerNumber || "",
            skills: formatPlayerSkills(player.playerSkills, player.position),
            instruction: player.playerInstruction?.value || "",
          })) || [],
        substitutes:
          team.substitutes?.map((player) => ({
            name: player.playerName || "Unknown Player",
            number: player.playerNumber || "",
            position: player.position || "ND",
            skills: formatPlayerSkills(player.playerSkills, player.position),
          })) || [],
        roles: mapRolesToPlayers(
          isTeamB ? team.teamRoles : rolesData,
          team.players,
          isTeamB
        ),
      };

      if (isTeamB) {
        formattedTeam.tactics = {
          ...DEFAULT_TACTICS,
          formation: team.formation || DEFAULT_TACTICS.formation,
          playingStyle: `The team will play in the characteristic style of ${
            team.fullName || team.teamName || "this team"
          }`,
        };
        return formattedTeam;
      }

      const {
        selectedGameStyleId,
        playingStyles = [],
        defensiveStyles = [],
        offensiveStyles = [],
      } = tactics || {};

      const selectedPlayingStyle = playingStyles.find(
        (style) => style.id === selectedGameStyleId
      );

      if (!selectedPlayingStyle) {
        console.warn(
          "Middleware: No matching playing style found, using default tactics"
        );
        formattedTeam.tactics = DEFAULT_TACTICS;
        return formattedTeam;
      }

      const playingStyleValue = selectedPlayingStyle.value || "Balanced";
      const selectedDefensiveStyle = defensiveStyles.find(
        (style) => style.id === selectedPlayingStyle.defense?.styleId
      );
      const defensiveStyleValue =
        selectedDefensiveStyle?.value || "Balanced defense";
      const defensiveDepth = selectedPlayingStyle.defense?.depth || 4;
      const selectedOffensiveStyle = offensiveStyles.find(
        (style) => style.id === selectedPlayingStyle.attack?.styleId
      );
      const offensiveStyleValue =
        selectedOffensiveStyle?.value || "Balanced offense";
      const playersInBox = selectedPlayingStyle.attack?.playersInBox || 2;

      formattedTeam.tactics = {
        formation: team.formation || "4-3-3",
        playingStyle: playingStyleValue,
        offensiveStyle: { playersInBox, style: offensiveStyleValue },
        defensiveStyle: { depth: defensiveDepth, style: defensiveStyleValue },
      };

      return formattedTeam;
    };

    const formattedTeamA = formatTeam(teamA);
    const formattedTeamB = formatTeam(teamB, true);

    if (!formattedTeamA || !formattedTeamB) {
      console.error("Middleware: Formatted teams are incomplete");
      store.dispatch(setError("Formatted team data is incomplete"));
      return next(action);
    }

    const matchData = {
      match: { teamA: formattedTeamA, teamB: formattedTeamB },
    };

    store
      .dispatch(initiateMatchSimulation(matchData))
      .unwrap()
      .then(({ timeline }) => {
        if (!Array.isArray(timeline)) {
          throw new Error("Invalid timeline format");
        }
        const completeMatchData = {
          match: {
            teams: { teamA: formattedTeamA, teamB: formattedTeamB },
            timeline,
          },
        };
        store.dispatch(fetchCompleteMatchData(completeMatchData));
      })
      .catch((error) => {
        console.error(
          `Middleware: Error in match simulation: ${error.message}`
        );
        store.dispatch(setError("Failed to complete match simulation"));
      });
  }

  return next(action);
};

export default formatTeamDataMiddleware;
