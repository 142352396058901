import React, { Suspense, lazy } from "react";
import ErrorBoundary from "./utils/ErrorBoundary";
import { AuthProvider, useAuth } from "./contexts/AuthProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PlayerProvider } from "./contexts/PlayerContext";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import ToastContainer from "@/components/Toast/ToastContainer";
import SEO from "@/components/common/SEO";

const AuthContent = lazy(() => import("./components/layout/AuthContent"));

const App = () => (
  <ErrorBoundary>
    <Providers>
      <MainContent />
    </Providers>
  </ErrorBoundary>
);

const Providers = ({ children }) => (
  <AuthProvider>
    <DndProvider backend={HTML5Backend}>
      <PlayerProvider>{children}</PlayerProvider>
    </DndProvider>
  </AuthProvider>
);

const MainContent = () => (
  <div
    className="flex flex-col min-h-screen dark:bg-darkbackgroundColor bg-backgroundColor"
    role="main"
    aria-label="Main content area"
  >
    <SEO 
      title="Simulador de Fútbol con IA"
      description="Crea tu equipo de fútbol o elige entre equipos reales y compite en simulaciones realistas con inteligencia artificial."
      image="URL_DE_TU_IMAGEN"
      url="https://futbolpro.net"
    />
    <Suspense fallback={<LoadingSpinner />}>
      <AuthContentWrapper />
    </Suspense>
    <ToastContainer />
  </div>
);

const AuthContentWrapper = () => {
  const { loading, currentUser } = useAuth();

  if (loading) {
    return (
      <div
        className="flex items-center justify-center h-full w-full"
        role="alert"
        aria-live="polite"
      >
        <LoadingSpinner />
      </div>
    );
  }

  return <AuthContent currentUser={currentUser} />;
};

export default App;
