
// ToastContainer.jsx

import React from "react";
import { useSelector } from "react-redux";
import Toast from "./Toast";

const ToastContainer = () => {
  const notifications = useSelector((state) => state.notification.notifications);

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 space-y-2">
      {notifications.map((notification) => (
        <Toast key={notification.id} {...notification} />
      ))}
    </div>
  );
};

export default ToastContainer;
