// playersSlice.jsx

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { swapPlayers } from "./teamSlice";
import { assignPlayerToRoleThunk } from "./rolesSlice";

const initialState = {
  selectedPlayers: [],
  selectedSubstitute: null,
  selectedPlayerForRole: null,
  customInstructions: {},
  playerInstructions: [
    {
      positions: ["POR"],
      commands: [
        {
          option: 1,
          value: "Stay on the line",
          title: "Mantente en la línea",
          description:
            "Quédate cerca de la línea de gol durante el juego abierto.",
        },
        {
          option: 2,
          value: "Quick clearance",
          title: "Despeje rápido",
          description:
            "Libera el balón rápidamente después de hacer una parada para iniciar un contraataque.",
        },
        {
          option: 3,
          value: "Organize the defense",
          title: "Organiza la defensa",
          description:
            "Dirige y organiza la línea defensiva y a tus compañeros cercanos.",
        },
        {
          option: 4,
          value: "Close angles",
          title: "Cierra ángulos",
          description:
            "Posiciónate para reducir los ángulos de tiro disponibles al atacante.",
        },
      ],
    },
    {
      positions: ["LD", "LI", "DFC", "CAD"],
      commands: [
        {
          option: 1,
          value: "Maintain the defensive line",
          title: "Mantener la línea",
          description:
            "Mantén la formación defensiva y evita descolgarte del resto de la defensa.",
        },
        {
          option: 2,
          value: "Clear the ball",
          title: "Despejar el balón",
          description:
            "Despeja el balón de tu área de defensa con seguridad cuando esté bajo presión.",
        },
        {
          option: 3,
          value: "Tight marking",
          title: "Marcaje estrecho",
          description:
            "Mantén un marcaje estrecho sobre el delantero asignado, especialmente en el área.",
        },
        {
          option: 4,
          value: "Cut passing lanes",
          title: "Cortar líneas de pase",
          description:
            "Posiciónate para interceptar o cortar líneas de pase hacia delanteros clave.",
        },
      ],
    },
    {
      positions: ["MCD", "MC", "MI", "MD", "MP", "MCO"],
      commands: [
        {
          option: 1,
          value: "Support the attack",
          title: "Apoya el ataque",
          description:
            "Mueve hacia adelante para ofrecer opciones de pase en el ataque.",
        },
        {
          option: 2,
          value: "Provide defensive cover",
          title: "Cobertura defensiva",
          description:
            "Regresa rápidamente para apoyar la defensa cuando el equipo pierde el balón.",
        },
        {
          option: 3,
          value: "Distribute the play",
          title: "Distribuye el juego",
          description:
            "Controla el medio campo con pases precisos y control del juego.",
        },
        {
          option: 4,
          value: "Pressure the opponent",
          title: "Presionar al oponente",
          description:
            "Aplica presión en el mediocampo para recuperar el balón.",
        },
      ],
    },
    {
      positions: ["DC", "DI", "EI", "ED", "E"],
      commands: [
        {
          option: 1,
          value: "Make runs behind defense",
          title: "Haz desmarques",
          description: "Realiza movimientos sin balón para abrir espacios.",
        },
        {
          option: 2,
          value: "Keep possession of the ball",
          title: "Mantén el balón",
          description: "Conserva la posesión y busca apoyo del equipo.",
        },
        {
          option: 3,
          value: "Shoot on goal",
          title: "Dispara a portería",
          description:
            "Aprovecha las oportunidades de tiro desde posiciones ventajosas.",
        },
        {
          option: 4,
          value: "Press the defense",
          title: "Presiona la defensa",
          description:
            "Inicia presión sobre los defensores para provocar errores.",
        },
      ],
    },
  ],
};

export const handlePlayerSelection = createAsyncThunk(
  "players/handlePlayerSelection",
  async (
    { player, index, isSubstitute },
    { dispatch, getState }
  ) => {
    const state = getState();
    const selectedRole = state.roles.selectedRole;
    const selectedPlayers = state.players.selectedPlayers;

    if (selectedRole) {
      dispatch(assignPlayerToRoleThunk(player.playerNumber, selectedRole, player.playerName));
      return;
    }

    if (selectedPlayers.some(p => p.player.id === player.id)) {
      dispatch(resetSelection());
      return;
    }

    const newSelection = { player, index, isSubstitute };
    dispatch(selectPlayer(newSelection));

    if (selectedPlayers.length === 1 && selectedPlayers[0].player.id !== player.id) {
      const firstSelectedPlayer = selectedPlayers[0].player;
      if (
        ((player.role.toUpperCase() === "Goalkeeper".toUpperCase() || player.role.toUpperCase() === "Portero".toUpperCase()) && 
        (firstSelectedPlayer.role.toUpperCase() !== "Goalkeeper".toUpperCase() && firstSelectedPlayer.role.toUpperCase() !== "Portero".toUpperCase())) ||
        ((player.role.toUpperCase() !== "Goalkeeper".toUpperCase() && player.role.toUpperCase() !== "Portero".toUpperCase()) && 
        (firstSelectedPlayer.role.toUpperCase() === "Goalkeeper".toUpperCase() || firstSelectedPlayer.role.toUpperCase() === "Portero".toUpperCase()))
      ) {
        dispatch(resetSelection());
        return;
      }
      dispatch(swapPlayers({ playerOne: selectedPlayers[0], playerTwo: newSelection }));
      dispatch(resetSelection());
    }
  }
);

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    selectPlayer: (state, action) => {
      state.selectedPlayers.push(action.payload);
    },
    resetSelection: (state) => {
      state.selectedPlayers = [];
      state.selectedPlayerForRole = null;
    },
    setCustomInstruction: (state, action) => {
      const { playerId, instruction } = action.payload;
      state.customInstructions[playerId] = instruction;
    },
    selectSubstitute: (state, action) => {
      state.selectedSubstitute = action.payload;
    },
    selectPlayerForRole: (state, action) => {
      state.selectedPlayerForRole = action.payload;
    },
  },
});

export const {
  selectPlayer,
  resetSelection,
  setCustomInstruction,
  selectSubstitute,
  selectPlayerForRole,
} = playersSlice.actions;

export default playersSlice.reducer;
