
// src/utils/icons.js

import { ReactComponent as LeftCornerIcon } from '@assets/left_corner.svg';
import { ReactComponent as RightCornerIcon } from '@assets/right_corner.svg';
import { ReactComponent as SoccerGoal } from '@assets/soccer_goal.svg';
import { ReactComponent as CaptainIcon } from '@assets/captain.svg';
import { ReactComponent as Simulate } from "@assets/simulate.svg";
import { ReactComponent as Strategy } from "@assets/strategy.svg";
import { ReactComponent as SoccerBallIcon } from "@assets/soccer_fill_ball.svg";
import { ReactComponent as Whistle } from '@assets/whistle.svg';
import { ReactComponent as Stopwatch } from '@assets/stopwatch.svg';
import { ReactComponent as Substitution } from '@assets/substitution.svg';
import { ReactComponent as YellowCard } from '@assets/yellow_card_icon.svg';
import { ReactComponent as RedCard } from '@assets/red_card_icon.svg';
import { ReactComponent as CommentaryIcon } from '@assets/commentary_icon.svg';
import { ReactComponent as SecondYellowCard } from '@assets/second_yellow_card_icon.svg';
import { ReactComponent as OffsideFlag } from '@assets/offside_flag.svg';
import { ReactComponent as Injury } from '@assets/injury.svg';
import { ReactComponent as Var } from '@assets/var.svg';
import { ReactComponent as InclinedYellowCard } from '@assets/inclined_yellow_card.svg';
import { ReactComponent as InclinedRedCard } from '@assets/inclined_red_card.svg';
import { ReactComponent as InclinedDoubleCard } from '@assets/inclined_double_card.svg';

export const icons = {
  soccer_ball: SoccerBallIcon,
  left_corner: LeftCornerIcon,
  right_corner: RightCornerIcon,
  soccer_goal: SoccerGoal,
  captain: CaptainIcon,
  strategy: Strategy,
  simulate: Simulate,
  whistle: Whistle,
  stopwatch: Stopwatch,
  substitution: Substitution,
  commentary: CommentaryIcon,
  yellow_card: YellowCard,
  red_card: RedCard,
  second_yellow_card: SecondYellowCard,
  offside_flag: OffsideFlag,
  injury: Injury,
  var: Var,
  inclined_yellow_card: InclinedYellowCard,
  inclined_red_card: InclinedRedCard,
  inclined_double_card: InclinedDoubleCard,
};
