import { icons } from "@/utils/icons";

export const CATEGORY_TYPES = {
  GOAL: "GOAL",
  YELLOW_CARD: "YELLOW_CARD",
  RED_CARD: "RED_CARD",
  SECOND_YELLOW_CARD: "SECOND_YELLOW_CARD",
  FOUL: "FOUL",
  FREE_KICK: "FREE_KICK",
  PENALTY_KICK: "PENALTY_KICK",
  SUBSTITUTION: "SUBSTITUTION",
  SAVE: "SAVE",
  OFFSIDE: "OFFSIDE",
  INJURY: "INJURY",
  CORNER_KICK: "CORNER_KICK",
  START: "START",
  HALF_TIME: "HALF_TIME",
  ADDED_TIME: "ADDED_TIME",
  FULL_TIME: "FULL_TIME",
  OWN_GOAL: "OWN_GOAL",
  FREE_KICK_GOAL: "FREE_KICK_GOAL",
  PENALTY_MISSED: "PENALTY_MISSED",
  OTHER: "OTHER",
  THROW_IN: "THROW_IN",
  DROP_BALL: "DROP_BALL",
  DISCIPLINE: "DISCIPLINE",
  TIME_ADDED: "TIME_ADDED",
  VAR_REVIEW: "VAR_REVIEW",
  RESTART: "RESTART",
  POSSIBLE_INJURY: "POSSIBLE_INJURY",
  DANGEROUS_ATTACK: "DANGEROUS_ATTACK",
  MISSED_OPPORTUNITY: "MISSED_OPPORTUNITY",
  COMMENTARY: "COMMENTARY",
};

export const getCategoryIcon = (categoryType) => {
  switch (categoryType) {
    case CATEGORY_TYPES.GOAL:
    case CATEGORY_TYPES.OWN_GOAL:
    case CATEGORY_TYPES.FREE_KICK_GOAL:
      return <icons.soccer_ball />;

    case CATEGORY_TYPES.INJURY:
    case CATEGORY_TYPES.POSSIBLE_INJURY:
      return <icons.injury />;

    case CATEGORY_TYPES.RED_CARD:
      return <icons.red_card />;

    case CATEGORY_TYPES.YELLOW_CARD:
      return <icons.yellow_card />;

    case CATEGORY_TYPES.VAR_REVIEW:
      return <icons.var />;

    case CATEGORY_TYPES.SUBSTITUTION:
      return <icons.substitution />;

    case CATEGORY_TYPES.START:
    case CATEGORY_TYPES.HALF_TIME:
    case CATEGORY_TYPES.FULL_TIME:
      return <icons.stopwatch />;

    case CATEGORY_TYPES.COMMENTARY:
    case CATEGORY_TYPES.SAVE:
    case CATEGORY_TYPES.MISSED_OPPORTUNITY:
    case CATEGORY_TYPES.DANGEROUS_ATTACK:
    case CATEGORY_TYPES.PENALTY_MISSED:
      return <icons.commentary />;

    case CATEGORY_TYPES.SECOND_YELLOW_CARD:
      return <icons.second_yellow_card />;

    case CATEGORY_TYPES.OFFSIDE:
      return <icons.offside_flag />;

    default:
      return <icons.whistle />;
  }
};
