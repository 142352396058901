import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { parseSimulationResult } from "@/middleware/parseSimulationResult";
import { CATEGORY_TYPES } from "@/utils/categories";
import mockSimulationResult from "./mockSimulationResult";

const USE_MOCK = false;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const MATCH_TIME_STEP = 27;
export const MAX_MATCH_TIME = 5400;
export const INTERVAL_DELAY = 100;

const initialState = {
  loadingTimeline: false,
  loadingStatistics: false,
  errorTimeline: null,
  errorStatistics: null,
  timeline: [],
  attackPercentage: [],
  highlights: [],
  completedMatchData: null,
  simulationCompleted: false,
  hasSimulated: false,
  navigationTriggered: false,
  localShotsData: { field: [], goal: [] },
  opponentShotsData: { field: [], goal: [] },
  playerHeatmaps: {},
  matchTime: 0,
  isMatchTimeRunning: false,
};

const fetchData = async (url, data) => {
  const response = await axios.post(`${API_BASE_URL}${url}`, data);
  if (!response?.data) throw new Error(`Invalid response from ${url}`);
  return response;
};

const timeToMinutes = (timeString) => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes + seconds / 60;
};

const ensureAscendingTimeline = (timeline) => {
  const firstMinute = timeToMinutes(timeline[0].minute);
  const lastMinute = timeToMinutes(timeline[timeline.length - 1].minute);
  return firstMinute > lastMinute ? timeline.reverse() : timeline;
};

const fillAttackPercentage = (existingData, length = 46) => {
  return Array.from({ length }, (_, i) => {
    const minute = i * 2;
    return existingData.get(minute) || {
      minute,
      local: Math.floor(Math.random() * 30) + 1,
      opponent: Math.floor(Math.random() * 30) + 1
    };
  });
};

export const fetchSimulationTimeline = createAsyncThunk(
  "matchSimulation/fetchSimulationTimeline",
  async (matchData, { rejectWithValue }) => {
    try {
      const result = await fetchData("/generateMatchResult", matchData);
      const { timeline = [], attackPercentage = [] } = parseSimulationResult(result) || {};
      
      console.log('RESPONSE fetchSimulationTimeline', JSON.stringify(timeline, null, 2)); 
      console.log('RESPONSE attackPercentage', JSON.stringify(attackPercentage, null, 2)); 

      if (!Array.isArray(timeline) || timeline.length === 0) {
        throw new Error("Invalid or empty simulation result");
      }

      const processedTimeline = ensureAscendingTimeline(timeline);
      const existingAttackData = new Map(attackPercentage.map(({ minute, ...rest }) => [minute, rest]));
      const processedAttackPercentage = fillAttackPercentage(existingAttackData);

      return {
        timeline: processedTimeline,
        attackPercentage: processedAttackPercentage
      };
    } catch (error) {
      return rejectWithValue(error.message || "Simulation timeline error");
    }
  }
);

export const fetchCompleteMatchData = createAsyncThunk(
  "matchSimulation/fetchCompleteMatchData",
  async (completeMatchData, { rejectWithValue }) => {
    try {
      const { data } = await fetchData("/simulateFullMatch", completeMatchData);
      return data;
    } catch (error) {
      return rejectWithValue(
        error.message || "Error fetching complete match data"
      );
    }
  }
);

export const initiateMatchSimulation = createAsyncThunk(
  "matchSimulation/initiateMatchSimulation",
  async (matchData, { dispatch }) => {
    return await dispatch(fetchSimulationTimeline(matchData)).unwrap();
  }
);

const matchSimulationSlice = createSlice({
  name: "matchSimulation",
  initialState,
  reducers: {
    initiateSimulation: (state) => {
      state.hasSimulated = false;
      state.simulationCompleted = false;
      state.errorTimeline = null;
      state.errorStatistics = null;
      state.navigationTriggered = false;
    },
    resetSimulationState: () => initialState,
    markNavigationTriggered: (state) => {
      state.navigationTriggered = true;
    },
    setError: (state, { payload }) => {
      state.errorTimeline = payload;
      state.errorStatistics = payload;
    },
    storeLocalShotsData: (state, { payload: { field = [], goal = [] } }) => {
      state.localShotsData = { field, goal };
    },
    storeOpponentShotsData: (state, { payload: { field = [], goal = [] } }) => {
       state.opponentShotsData = { field, goal };
    },
    storePlayerHeatmap: (state, { payload: { playerId, heatmapData } }) => {
      state.playerHeatmaps[playerId] = heatmapData;
    },
    incrementMatchTime: (state) => {
      state.matchTime = Math.min(
        state.matchTime + MATCH_TIME_STEP,
        MAX_MATCH_TIME
      );
      if (state.matchTime >= MAX_MATCH_TIME) {
        state.simulationCompleted = true;
        state.isMatchTimeRunning = false;
      }
    },
    resetMatchTime: (state) => {
      state.matchTime = 0;
    },
    setMatchTimeRunning: (state, { payload }) => {
      state.isMatchTimeRunning = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimulationTimeline.pending, (state) => {
        state.loadingTimeline = true;
        state.errorTimeline = null;
        state.timeline = [];
        state.attackPercentage = [];
        state.highlights = [];
      })
      .addCase(fetchSimulationTimeline.fulfilled, (state, { payload }) => {
        state.timeline = payload.timeline;
        state.attackPercentage = payload.attackPercentage;
        state.loadingTimeline = false;
        state.simulationCompleted = false;
        state.hasSimulated = true;

        const highlightCategories = [
          CATEGORY_TYPES.START,
          CATEGORY_TYPES.GOAL,
          CATEGORY_TYPES.OWN_GOAL,
          CATEGORY_TYPES.FREE_KICK_GOAL,
          CATEGORY_TYPES.OFFSIDE,
          CATEGORY_TYPES.RED_CARD,
          CATEGORY_TYPES.FULL_TIME,
          CATEGORY_TYPES.YELLOW_CARD,
        ];        

        state.highlights = state.timeline.reduce((acc, item, index) => {
          if (highlightCategories.includes(item.category)) {
            acc.push({ ...item, id: index, thumbnail: "" });
          }
          return acc;
        }, []);

        console.log('state.highlights', JSON.stringify(state.highlights, null, 2));
      })
      .addCase(fetchSimulationTimeline.rejected, (state, { payload }) => {
        state.errorTimeline = payload;
        state.loadingTimeline = false;
        state.simulationCompleted = false;
        state.hasSimulated = false;
      })
      .addCase(fetchCompleteMatchData.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
        state.completedMatchData = null;
      })
      .addCase(fetchCompleteMatchData.fulfilled, (state, { payload }) => {
        state.completedMatchData = payload.completedMatchData || payload; 

        console.log('RESPONSE ACA!!!!!!', JSON.stringify(payload, null, 2));
        state.loadingStatistics = false;
      })
      .addCase(fetchCompleteMatchData.rejected, (state, { payload }) => {
        state.errorStatistics = payload;
        state.loadingStatistics = false;
      })      
      .addCase(initiateMatchSimulation.rejected, (state, { payload }) => {
        state.errorTimeline = payload;
        state.loadingTimeline = false;
        state.simulationCompleted = false;
        state.hasSimulated = false;
      });
  },
});

let intervalId = null;

export const startMatchTimeSimulation = () => (dispatch, getState) => {
  const { isMatchTimeRunning, matchTime } = getState().matchSimulation;
  if (isMatchTimeRunning || matchTime >= MAX_MATCH_TIME) return;

  dispatch(setMatchTimeRunning(true));

  intervalId = setInterval(() => {
    const { matchTime: currentTime } = getState().matchSimulation;
    if (currentTime >= MAX_MATCH_TIME) {
      clearInterval(intervalId);
      intervalId = null;
      dispatch(setMatchTimeRunning(false));
      return;
    }
    dispatch(incrementMatchTime());
  }, INTERVAL_DELAY);
};

export const stopMatchTimeSimulation = () => (dispatch) => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
    dispatch(setMatchTimeRunning(false));
    dispatch(resetMatchTime());
  }
};

export const selectMatchSimulationState = (state) => state.matchSimulation;

export const {
  initiateSimulation,
  markNavigationTriggered,
  setError,
  resetSimulationState,
  storeLocalShotsData,
  storeOpponentShotsData,
  storePlayerHeatmap,
  incrementMatchTime,
  resetMatchTime,
  setMatchTimeRunning,
} = matchSimulationSlice.actions;

export default matchSimulationSlice.reducer;
