// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCCPpI8p0CgQ8gXH_4sva_9bJUMFIFgnYw",
  authDomain: "futbolsimulator-38530.firebaseapp.com",
  databaseURL: "https://futbolsimulator-38530-default-rtdb.firebaseio.com",
  projectId: "futbolsimulator-38530",
  storageBucket: "futbolsimulator-38530.appspot.com",
  messagingSenderId: "638924088609",
  appId: "1:638924088609:web:3992ca7bebe1e3313d0a60",
  measurementId: "G-XG8W1ZL6MM"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const realtimeDb = getDatabase(app);

export { app, auth, db, functions, storage, realtimeDb };
