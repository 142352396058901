// rolesSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { resetSelection } from './playersSlice';
import { updateCaptain } from '@/features/teamSlice';

/**
 * @typedef {Object} Player
 * @property {number} playerNumber
 * @property {string} playerName
 */

/**
 * @typedef {Object} RoleData
 * @property {string} roleKey
 * @property {string} role
 * @property {string|null} player
 * @property {number|null} playerNumber
 * @property {string} icon
 */

/**
 * @typedef {Object} RolesState
 * @property {string|null} selectedRole
 * @property {RoleData[]} rolesData
 * @property {string|null} selectedPlayer
 */

/** @type {RolesState} */
const initialState = {
  selectedRole: null,
  rolesData: [
    { roleKey: "captain", role: "Capitán del equipo", player: null, playerNumber: null, icon: "captain" },
    { roleKey: "left_free_kick", role: "Tiro libre izquierdo", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "right_free_kick", role: "Tiro libre derecho", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "long_free_kick", role: "Tiro libre largo", player: null, playerNumber: null, icon: "soccer_goal" },
    { roleKey: "penalty_kick", role: "Tiro de penales", player: null, playerNumber: null, icon: "soccer_ball" },
    { roleKey: "left_corner", role: "Corner izquierdo", player: null, playerNumber: null, icon: "left_corner" },
    { roleKey: "right_corner", role: "Corner derecho", player: null, playerNumber: null, icon: "right_corner" },
  ],
  selectedPlayer: null,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    /**
     * Selects a role
     * @param {RolesState} state
     * @param {{payload: string|null}} action
     */
    selectRole: (state, action) => {
      state.selectedRole = action.payload;
    },

    /**
     * Assigns a player to a role
     * @param {RolesState} state
     * @param {{payload: {playerNumber: number, roleKey: string, playerName?: string}}} action
     */
    assignPlayerToRole: (state, action) => {
      const { playerNumber, roleKey, playerName } = action.payload;
      const role = state.rolesData.find(r => r.roleKey === roleKey);
      if (role) {
        role.player = playerName;
        role.playerNumber = playerNumber;
        if (roleKey === "captain") {
          updateCaptain({ newCaptainId: playerNumber });
        }
      }
      state.selectedRole = null;
    },

    /**
     * Updates all team roles
     * @param {RolesState} state
     * @param {{payload: {teamRoles: Object.<string, number>, players: Player[]}}} action
     */
    updateTeamRoles: (state, action) => {
      const { teamRoles, players } = action.payload;
      state.rolesData.forEach(role => {
        const playerNumber = teamRoles[role.roleKey];
        const player = players.find(p => p.playerNumber === playerNumber);
        role.player = player ? player.playerName : null;
        role.playerNumber = player ? playerNumber : null;
      });
    },

    /**
     * Clears the selected role
     * @param {RolesState} state
     */
    clearSelectedRole: (state) => {
      state.selectedRole = null;
    },
  },
});

export const { 
  selectRole, 
  assignPlayerToRole, 
  updateTeamRoles, 
  clearSelectedRole 
} = rolesSlice.actions;

export default rolesSlice.reducer;

/**
 * Thunk to select a role and deselect players if necessary
 * @param {string|null} roleKey
 * @returns {import('@reduxjs/toolkit').ThunkAction}
 */
export const selectRoleAndDeselectPlayers = (roleKey) => (dispatch) => {
  dispatch(selectRole(roleKey));
  if (!roleKey) {
    dispatch(resetSelection());
  }
};

/**
 * Thunk to assign a player to a role
 * @param {number} playerNumber
 * @param {string} roleKey
 * @returns {import('@reduxjs/toolkit').ThunkAction}
 */
export const assignPlayerToRoleThunk = (playerNumber, roleKey, playerName) => (dispatch) => {
  dispatch(assignPlayerToRole({ playerNumber, roleKey, playerName }));
  dispatch(clearSelectedRole());
};

export const updateRoles = (team) => (dispatch) => {
  if (team?.teamRoles && team?.players) {
    dispatch(updateTeamRoles({ teamRoles: team.teamRoles, players: team.players }));
  }
};
