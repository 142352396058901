
// stepperSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  steps: [
    {
      id: 1,
      label: "Equipos",
      description: "Define equipos, o elije un partido",
      path: "/teams",
      icon: "soccer_ball",
      buttonId: "continue",
    },
    {
      id: 2,
      label: "Táctica",
      description: "Ajusta estrategias y alineaciones del equipo.",
      path: "/tactics",
      icon: "strategy",
      buttonId: "simulate",
    },
    {
      id: 3,
      label: "Simular",
      description: "Simula el resultado del partido configurado.",
      path: "/result",
      icon: "simulate",
      buttonId: "view_summary",
    },
  ],
  activeStep: 1,
  stepsEnabled: { 1: true, 2: false, 3: false }
};

const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setStepEnabled: (state, action) => {
      const { stepId, enabled } = action.payload;
      state.stepsEnabled[stepId] = enabled;
    },
    setActiveStepByPath: (state, action) => {
      const step = state.steps.find(step => step.path === action.payload);
      if (step) {
        state.activeStep = step.id;
        state.stepsEnabled = { ...state.stepsEnabled, [step.id]: true };
      }
    },
    resetSteps: (state) => {
      state.stepsEnabled[2] = false;
      state.stepsEnabled[3] = false;
    }
  }
});

export const { setActiveStep, setStepEnabled, setActiveStepByPath, resetSteps } = stepperSlice.actions;

export default stepperSlice.reducer;
