import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setJerseyConfiguration } from "./jerseySlice";
import { getFirestore, collection, getDocs, doc, getDoc, DocumentReference } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const LEVEL_COLORS = {
  1: "#004799",
  2: "#009372",
  3: "#bd369c",
  4: "#F5A623",
};

const initialState = {
  myIdealTeam: {
    teamRef: null,
    starters: [],
    substitutes: [],
    captainId: null,
    jersey: {},
    teamShield: { index: 0, color: ["#FF0000", "#00FF00"] },
  },
  totalPlayers: 0,
  progress: 0,
  customInstructions: {},
  positionFilters: {},
  loading: false,
  error: null,
  playersByLevel: {
    level1: [],
    level2: [],
    level3: [],
  },
};

const processPlayers = async (snap, level) => {
  return await Promise.all(
    snap.docs.map(async (doc) => {
      const data = doc.data();

      const teamLogoRef = data.teamLogo instanceof DocumentReference ? data.teamLogo.id : null;
      const countryFlagRef = data.countryFlag instanceof DocumentReference ? data.countryFlag.id : null;

      return {
        id: doc.id,
        ...data,
        teamLogo: teamLogoRef,
        countryFlag: countryFlagRef,
        cardColor: LEVEL_COLORS[level] || "#000",
      };
    })
  );
};

const fetchPlayersData = async () => {
  const db = getFirestore();

  const [levelOnePlayersSnap, levelTwoPlayersSnap, levelThreePlayersSnap] =
    await Promise.all([
      getDocs(collection(db, "playersCards/levelOne/players")),
      getDocs(collection(db, "playersCards/levelTwo/players")),
      getDocs(collection(db, "playersCards/levelThree/players")),
    ]);

  const playersByLevel = {
    level1: await processPlayers(levelOnePlayersSnap, 1),
    level2: await processPlayers(levelTwoPlayersSnap, 2),
    level3: await processPlayers(levelThreePlayersSnap, 3),
  };

  const allPlayersMap = [
    ...playersByLevel.level1,
    ...playersByLevel.level2,
    ...playersByLevel.level3,
  ].reduce((acc, player) => {
    acc[player.id] = player;
    return acc;
  }, {});

  return { playersByLevel, allPlayersMap };
};

const fetchUserIdealTeam = async (userId) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return (
      userData.idealTeam || {
        starters: [],
        substitutes: [],
        captainId: null,
        jersey: {},
        teamShield: { index: 0, color: ["#FF0000", "#00FF00"] },
      }
    );
  } else {
    throw new Error("User does not have an ideal team saved.");
  }
};

const fetchPlayersAndIdealTeam = createAsyncThunk(
  "idealTeam/fetchPlayersAndIdealTeam",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const userId = state.auth.currentUser?.uid;

      if (!userId) {
        throw new Error("User ID is not available.");
      }

      const [playersData, idealTeam] = await Promise.all([
        fetchPlayersData(),
        fetchUserIdealTeam(userId),
      ]);

      const { playersByLevel, allPlayersMap } = playersData;

      const populateTeam = (teamList) =>
        teamList
          .map(({ player, order }) => ({
            ...allPlayersMap[player],
            order,
          }))
          .filter(Boolean)
          .sort((a, b) => a.order - b.order);

      const populatedIdealTeam = {
        starters: populateTeam(idealTeam.starters),
        substitutes: populateTeam(idealTeam.substitutes),
        captainId: idealTeam.captainId,
        jersey: idealTeam.jersey,
        teamShield: idealTeam.teamShield,
        teamRef: idealTeam.teamRef || null,
      };

      const currentCombination =
        state.jersey.jerseyCombinations[state.jersey.currentCombinationIndex];
      if (
        idealTeam.jersey &&
        JSON.stringify(currentCombination) !== JSON.stringify(idealTeam.jersey)
      ) {
        dispatch(setJerseyConfiguration(idealTeam.jersey));
      }

      return {
        playersByLevel,
        myIdealTeam: populatedIdealTeam,
      };
    } catch (error) {
      console.error("Error fetching players or the ideal team:", error);
      return rejectWithValue(error.message);
    }
  }
);

const createTeamData = (
  username,
  updatedPlayers,
  substitutes,
  captainId,
  currentCombination
) => {
  let captainNumber;

  const mapPlayerData = (player, isCaptain = false) => {
    const playerData = {
      id: player.id,
      playerName: player.playerName,
      playerNumber: player.playerNumber,
      playerSkills: player.playerSkills,
      position: player.position,
      role: player.role,
      playerImage: player.playerImage || "",
      playerInstruction: {
        option: -1,
        value: "",
      },
    };

    if (isCaptain) captainNumber = playerData.playerNumber;

    return playerData;
  };

  const players = updatedPlayers.map((player) =>
    mapPlayerData(player, player.id === captainId)
  );

  if (!captainNumber) {
    captainNumber = players[Math.floor(Math.random() * players.length)].playerNumber;
  }

  const substitutesData = substitutes.map((player) => ({
    ...mapPlayerData(player),
    playerInstruction: { option: -1, value: "" },
  }));

  return {
    formation: "4-3-3",
    players,
    substitutes: substitutesData,
    teamDetails: {
      fullName: `${username} FC`,
      primaryColor: currentCombination.shirtColor,
      secondaryColor: currentCombination.shirtStyleColor,
      season: new Date().getFullYear().toString(),
    },
    teamRoles: {
      captain: captainNumber,
      left_corner: 0,
      left_free_kick: 0,
      long_free_kick: 0,
      penalty_kick: 0,
      right_corner: 0,
      right_free_kick: 0,
    },
  };
};

export const saveIdealTeamThunk = createAsyncThunk(
  "idealTeam/saveIdealTeam",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { substitutes, captainId, starters } = state.idealTeam.myIdealTeam;
      const { jerseyCombinations, currentCombinationIndex } = state.jersey;
      const currentCombination = jerseyCombinations[currentCombinationIndex];
      const userId = state.auth.currentUser?.uid;
      const username = state.auth.currentUser?.username;

      if (
        !userId ||
        !username ||
        substitutes === undefined ||
        captainId === null ||
        !currentCombination ||
        starters === undefined
      ) {
        throw new Error("All fields are required.");
      }

      const captainExists = starters.some((player) => player.id === captainId);
      if (!captainExists) {
        captainId = starters[Math.floor(Math.random() * starters.length)].id;
      }

      const teamData = createTeamData(
        username,
        starters,
        substitutes,
        captainId,
        currentCombination
      );

      try {
        JSON.stringify(teamData);
      } catch (e) {
        throw new Error("teamData contains circular references.");
      }

      const functions = getFunctions();
      const saveIdealTeamFunction = httpsCallable(functions, "saveIdealTeam");

      const response = await saveIdealTeamFunction({
        updatedPlayers: starters,
        substitutes,
        captainId,
        jerseyCombination: currentCombination,
        teamData,
        teamShield: state.idealTeam.myIdealTeam.teamShield,
      });

      if (response.data.success) {
        return {
          starters: starters.map((player, index) => ({
            order: index + 1,
            player: player.id,
          })),
          substitutes: substitutes.map((player) => ({
            player: player.id,
          })),
          captainId,
          jersey: currentCombination,
        };
      } else {
        throw new Error(
          response.data.message || "Error saving the ideal team in Firebase."
        );
      }
    } catch (error) {
      console.error("Error saving the ideal team:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const idealTeamSlice = createSlice({
  name: "idealTeam",
  initialState,
  reducers: {
    setPositionFilter: (state, action) => {
      const { level, position } = action.payload;
      state.positionFilters[level] = position ?? null;
    },
    resetPositionFilter: (state, action) => {
      const { level } = action.payload;
      state.positionFilters[level] = null;
    },
    addPlayer: (state, action) => {
      const newPlayer = action.payload;
      if (state.myIdealTeam.starters.length < 11) {
        const order = state.myIdealTeam.starters.length + 1;
        state.myIdealTeam.starters.push({ ...newPlayer, order });
      } else if (state.myIdealTeam.substitutes.length < 5) {
        state.myIdealTeam.substitutes.push(newPlayer);
      }
      state.totalPlayers += 1;
      state.progress = (state.totalPlayers / 16) * 100;
    },
    removePlayer: (state, action) => {
      const { list, index } = action.payload;
      if (list === "starters") {
        state.myIdealTeam.starters.splice(index, 1);
        state.myIdealTeam.starters = state.myIdealTeam.starters.map(
          (player, i) => ({
            ...player,
            order: i + 1,
          })
        );
      } else if (list === "substitutes") {
        state.myIdealTeam.substitutes.splice(index, 1);
      }
      state.totalPlayers -= 1;
      state.progress = (state.totalPlayers / 16) * 100;
    },
    movePlayer: (state, action) => {
      const { sourceIndex, destIndex, sourceList, destList } = action.payload;

      if (sourceIndex === undefined || destIndex === undefined) return;
      if (sourceIndex === destIndex && sourceList === destList) return;

      const sourceArray = state.myIdealTeam[sourceList];
      const destArray = state.myIdealTeam[destList];

      if (!sourceArray || !destArray || sourceArray.length === 0) return;

      const [movedPlayer] = sourceArray.splice(sourceIndex, 1);

      if (sourceList === "starters" && destList === "starters") {
        sourceArray.splice(destIndex, 0, movedPlayer);
        state.myIdealTeam.starters = sourceArray.map((player, i) => ({
          ...player,
          order: i + 1,
        }));
      } else {
        if (destIndex >= destArray.length) {
          destArray.push(movedPlayer);
        } else {
          destArray.splice(destIndex, 0, movedPlayer);
        }
      }
    },
    setSelectedPlayers: (state, action) => {
      state.selectedPlayers = action.payload;
    },
    selectSubstitute: (state, action) => {
      state.selectedSubstitute = action.payload;
    },
    setCaptain: (state, action) => {
      state.myIdealTeam.captainId = action.payload;
    },
    setTeamShield: (state, action) => {
      state.myIdealTeam.teamShield = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayersAndIdealTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayersAndIdealTeam.fulfilled, (state, action) => {
        state.playersByLevel = action.payload.playersByLevel;
        state.myIdealTeam = action.payload.myIdealTeam;
        state.loading = false;
      })
      .addCase(fetchPlayersAndIdealTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveIdealTeamThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveIdealTeamThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveIdealTeamThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  addPlayer,
  removePlayer,
  movePlayer,
  setSelectedPlayers,
  selectSubstitute,
  setCaptain,
  setPositionFilter,
  resetPositionFilter,
  setTeamShield,
} = idealTeamSlice.actions;

export const ALL_POSITIONS = null;
export const MAX_PLAYERS = 16;

export const getPositionFilter = (state, level) =>
  state.idealTeam.positionFilters[level] ?? ALL_POSITIONS;

export const selectPlayersByLevel = (state, level) => {
  const filter = getPositionFilter(state, level);
  const players = state.idealTeam.playersByLevel[`level${level}`] || [];
  return filter === ALL_POSITIONS
    ? players
    : players.filter((player) => player.position === filter);
};

export const selectPlayerCount = (state) => {
  const startersCount = state.idealTeam.myIdealTeam.starters.length;
  const substitutesCount = state.idealTeam.myIdealTeam.substitutes.length;
  return startersCount + substitutesCount;
};

export const selectIsComplete = (state) => selectPlayerCount(state) === MAX_PLAYERS;

export const selectLoading = (state) => state.idealTeam?.loading ?? false;

export const selectError = (state) => state.idealTeam?.error ?? null;

export const getSelectedPlayerForRole = (state) =>
  state.idealTeam.selectedPlayerForRole;

export { fetchPlayersAndIdealTeam, saveIdealTeamThunk as saveIdealTeam };

export default idealTeamSlice.reducer;
