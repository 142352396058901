import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "@assets/fp_loading.json";

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <Lottie animationData={loadingAnimation} loop style={{ width: '150px', height: '150px' }} />
  </div>
);

export default LoadingSpinner;
