// mockSimulationResult.js

import { CATEGORY_TYPES } from "@/utils/categories"; // Importación necesaria para las categorías

const mockSimulationResult = {
  timeline: [
    {
      "minute": "00:00",
      "category": "START",
      "title": "Kick-off",
      "description": "¡El estadio está a reventar! Los equipos están listos, la afición está enloquecida y el árbitro da la señal. ¡Comienza el espectáculo!",
      "side": "neutral",
      "score": {
        "local": 0,
        "opponent": 0
      },
      "isHighlighted": true
    },
    {
      "minute": "05:12",
      "category": "DANGEROUS_ATTACK",
      "title": "Barcelona presiona",
      "description": "Barcelona comienza a tomar el control del partido, con Messi y Neymar combinando en el ataque. La defensa de Parma se ve obligada a retroceder, mientras el público se levanta en anticipación.",
      "side": "opponent",
      "score": {
        "local": 0,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "10:45",
      "category": "MISSED_OPPORTUNITY",
      "title": "Oportunidad fallida",
      "description": "Luis Suárez recibe un pase en el área, pero su disparo se va desviado. ¡Qué oportunidad perdida para abrir el marcador! La afición de Barcelona se lamenta.",
      "side": "opponent",
      "score": {
        "local": 0,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "15:30",
      "category": "FOUL",
      "title": "Falta de Parma",
      "description": "Felipe comete una falta sobre Neymar cerca del área. El árbitro no duda en señalar la infracción. Barcelona tiene una buena oportunidad para crear peligro.",
      "side": "local",
      "score": {
        "local": 0,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "16:00",
      "category": "FREE_KICK",
      "title": "Tiro libre para Barcelona",
      "description": "Lionel Messi se prepara para ejecutar el tiro libre. La barrera de Parma se coloca, mientras los aficionados contienen la respiración. ¡Puede ser un momento decisivo!",
      "side": "opponent",
      "score": {
        "local": 0,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "16:15",
      "category": "FREE_KICK_GOAL",
      "title": "Gol de Messi",
      "description": "¡¡¡GOOOOOOOOOOOOOOOOOOOOOOL!!! ¡Qué golazo monumental de Lionel Messi! Con un tiro libre magistral, coloca el balón en la esquina superior. ¡El estadio estalla de alegría!",
      "side": "opponent",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "goalLocation": "CENTRAL_FREE_KICK",
      "assist": null,
      "isHighlighted": true
    },
    {
      "minute": "20:00",
      "category": "COMMENTARY",
      "title": "Barcelona domina el juego",
      "description": "Barcelona está mostrando su calidad en el campo. Cada pase es preciso, cada movimiento es sincronizado. La afición está enloquecida, ¡esto es fútbol en su máxima expresión!",
      "side": "neutral",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "25:45",
      "category": "DANGEROUS_ATTACK",
      "title": "Parma responde",
      "description": "Parma intenta reaccionar con un contraataque rápido. Nocerino avanza por la banda, pero la defensa de Barcelona se reorganiza rápidamente. ¡Gran trabajo defensivo!",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "30:30",
      "category": "YELLOW_CARD",
      "title": "Tarjeta amarilla para Jorquera",
      "description": "Cristóbal Jorquera recibe una tarjeta amarilla por una falta dura sobre Messi. El árbitro no duda en mostrar la tarjeta, y la afición de Barcelona lo celebra.",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "35:00",
      "category": "CORNER_KICK",
      "title": "Córner para Barcelona",
      "description": "Barcelona gana un córner tras un buen intento de Suárez. Neymar se prepara para ejecutarlo, mientras la defensa de Parma se coloca en su lugar.",
      "side": "opponent",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "35:15",
      "category": "SAVE",
      "title": "Gran parada de Mirante",
      "description": "Neymar lanza el córner y el balón llega a la cabeza de Piqué, pero Antonio Mirante realiza una espectacular parada. ¡Qué reflejos del portero de Parma!",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": true
    },
    {
      "minute": "40:00",
      "category": "HALF_TIME",
      "title": "Final del primer tiempo",
      "description": "El árbitro pita el final del primer tiempo. Barcelona ha dominado el juego, pero Parma ha mostrado destellos de calidad. ¡Todo por decidir en la segunda mitad!",
      "side": "neutral",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": true
    },
    {
      "minute": "45:00",
      "category": "START",
      "title": "Inicio del segundo tiempo",
      "description": "¡Comienza la segunda mitad! Parma necesita reaccionar si quiere cambiar el rumbo del partido. La afición está lista para apoyar a su equipo.",
      "side": "neutral",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": true
    },
    {
      "minute": "50:30",
      "category": "DANGEROUS_ATTACK",
      "title": "Parma busca el empate",
      "description": "Parma inicia un ataque prometedor, con Mauri llevando el balón hacia adelante. La defensa de Barcelona se ve presionada, ¡esto puede ser peligroso!",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 0
      },
      "isHighlighted": false
    },
    {
      "minute": "55:00",
      "category": "GOAL",
      "title": "Gol de Coda",
      "description": "¡¡¡GOOOOOOOOOOOOOOOOOOOOOOL!!! ¡Massimo Coda empata el partido! Con un disparo preciso desde el borde del área, el balón se cuela en la esquina. ¡El estadio estalla de júbilo!",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "goalLocation": "INSIDE_AREA",
      "assist": "José Mauri",
      "isHighlighted": true
    },
    {
      "minute": "60:00",
      "category": "COMMENTARY",
      "title": "Partido empatado",
      "description": "El partido está empatado y la tensión se siente en el aire. Ambos equipos luchan por el control del juego, ¡esto se ha vuelto emocionante!",
      "side": "neutral",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "65:15",
      "category": "FOUL",
      "title": "Falta de Piqué",
      "description": "Gerard Piqué comete una falta sobre Ishak Belfodil. El árbitro señala la infracción y Parma tiene una buena oportunidad para crear peligro.",
      "side": "opponent",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "66:00",
      "category": "FREE_KICK",
      "title": "Tiro libre para Parma",
      "description": "Cristóbal Jorquera se prepara para ejecutar el tiro libre. La barrera de Barcelona se coloca, mientras los aficionados de Parma esperan con ansias.",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "66:15",
      "category": "SAVE",
      "title": "Gran parada de ter Stegen",
      "description": "Jorquera lanza el tiro libre, pero Marc-André ter Stegen se estira y logra desviar el balón. ¡Qué gran intervención del portero!",
      "side": "opponent",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": true
    },
    {
      "minute": "70:00",
      "category": "SUBSTITUTION",
      "title": "Cambio en Parma",
      "description": "Parma realiza su primer cambio, ingresando a un nuevo delantero para buscar más profundidad en el ataque. ¡El entrenador busca cambiar el rumbo del partido!",
      "side": "local",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "75:00",
      "category": "DANGEROUS_ATTACK",
      "title": "Barcelona presiona nuevamente",
      "description": "Barcelona intensifica su presión, con Messi y Suárez combinando en el área. La defensa de Parma se ve obligada a retroceder, ¡esto puede ser peligroso!",
      "side": "opponent",
      "score": {
        "local": 1,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "90:30",
      "category": "GOAL",
      "title": "Gol de Suárez",
      "description": "¡¡¡GOOOOOOOOOOOOOOOOOOOOOOL!!! ¡Luis Suárez vuelve a poner a Barcelona en ventaja! Con un potente disparo desde dentro del área, el balón se cuela en la red. ¡El estadio estalla de alegría!",
      "side": "opponent",
      "score": {
        "local": 2,
        "opponent": 1
      },
      "goalLocation": "INSIDE_AREA",
      "assist": "Neymar",
      "isHighlighted": true
    },
    {
      "minute": "85:00",
      "category": "COMMENTARY",
      "title": "Barcelona controla el juego",
      "description": "Barcelona ha recuperado el control del partido tras el gol de Suárez. La afición está enloquecida, y el equipo busca asegurar la victoria.",
      "side": "neutral",
      "score": {
        "local": 2,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "90:00",
      "category": "ADDED_TIME",
      "title": "Tiempo añadido",
      "description": "El árbitro señala 3 minutos de tiempo añadido. Parma necesita un gol para empatar, ¡la tensión es palpable!",
      "side": "neutral",
      "score": {
        "local": 2,
        "opponent": 1
      },
      "isHighlighted": false
    },
    {
      "minute": "90:45",
      "category": "FULL_TIME",
      "title": "Final del partido",
      "description": "¡El árbitro pita el final del partido! Barcelona se lleva la victoria por 2-1 en un emocionante encuentro. La afición celebra mientras Parma se retira con la cabeza en alto.",
      "side": "neutral",
      "score": {
        "local": 2,
        "opponent": 1
      },
      "isHighlighted": true
    }
  ],
  attackPercentage:  [
    {
      minute: 0,
      local: 45,
      opponent: 55,
    },
    {
      minute: 5,
      local: 50,
      opponent: 50,
    },
    {
      minute: 10,
      local: 40,
      opponent: 60,
    },
    {
      minute: 15,
      local: 55,
      opponent: 45,
    },
    {
      minute: 20,
      local: 60,
      opponent: 40,
    },
    {
      minute: 25,
      local: 50,
      opponent: 50,
    },
    {
      minute: 30,
      local: 45,
      opponent: 55,
    },
    {
      minute: 35,
      local: 40,
      opponent: 60,
    },
    {
      minute: 40,
      local: 50,
      opponent: 50,
    },
    {
      minute: 45,
      local: 55,
      opponent: 45,
    },
    {
      minute: 50,
      local: 45,
      opponent: 55,
    },
    {
      minute: 55,
      local: 40,
      opponent: 60,
    },
    {
      minute: 60,
      local: 50,
      opponent: 50,
    },
    {
      minute: 65,
      local: 55,
      opponent: 45,
    },
    {
      minute: 70,
      local: 60,
      opponent: 40,
    },
    {
      minute: 75,
      local: 50,
      opponent: 50,
    },
    {
      minute: 80,
      local: 55,
      opponent: 45,
    },
    {
      minute: 85,
      local: 60,
      opponent: 40,
    },
    {
      minute: 90,
      local: 65,
      opponent: 35,
    },
  ],
  completedMatchData: {
    statistics: {
      local: {
        goals: [
          {
            player: "Lionel Messi",
            minute: "16:15",
            type: "FREE_KICK",
          },
          {
            player: "Lautaro Martínez",
            minute: "85:15",
            type: "INSIDE_AREA",
          },
        ],
        stats: {
          possession: 50,
          shots: 8,
          onTarget: 5,
          fouls: 3,
          offsides: 1,
          passes: 300,
          corners: 3,
          yellowCards: [
            {
              player: "Puyol",
              minute: "15:30",
            },
            {
              player: "Busquets",
              minute: "40:00",
            },
          ],
          redCards: [],
          penalties: 0,
        },
      },
      opponent: {
        goals: [
          {
            player: "David Villa",
            minute: "60:15",
            type: "INSIDE_AREA",
          },
        ],
        stats: {
          possession: 50,
          shots: 7,
          onTarget: 3,
          fouls: 2,
          offsides: 0,
          passes: 290,
          corners: 2,
          yellowCards: [],
          redCards: [],
          penalties: 0,
        },
      },
    },
    postMatchSummary: {
      title: "Argentina se lleva la victoria ante España",
      subtitle: "Un emocionante partido con goles de Messi y Lautaro Martínez",
      content:
        "En un encuentro vibrante, Argentina logró imponerse a España con un marcador de 2-1. Lionel Messi abrió el marcador con un tiro libre magistral en el minuto 16, lo que encendió la pasión de la afición local. A pesar de la presión ejercida por España, que logró empatar a través de David Villa en el minuto 60, Argentina no se dejó amedrentar y respondió rápidamente con un gol de Lautaro Martínez, quien definió con potencia tras un gran pase de Messi, sellando así la victoria en el minuto 85.\n\nEl partido estuvo marcado por un gran despliegue de habilidades individuales y tácticas de ambos equipos. Argentina mostró una sólida defensa, con Emiliano Martínez realizando intervenciones clave, mientras que la delantera, liderada por Messi, fue constante en la búsqueda de oportunidades. España, aunque mostró destellos de calidad, no pudo concretar sus ocasiones y se quedó con las manos vacías. La afición argentina celebró con euforia el triunfo de su selección, que sigue demostrando su fortaleza en el campo.",
      matchTopPlayers: [
        {
          playerNumber: 10,
          position: "EI",
          name: "Lionel Messi",
          score: 9,
          goals: 1,
          assists: 1,
          completedPasses: 50,
          image: null,
          heatMap: {
            x: 60,
            y: 40,
            intensity: 0.8,
          },
        },
        {
          playerNumber: 22,
          position: "DC",
          name: "Lautaro Martínez",
          score: 8,
          goals: 1,
          assists: 0,
          completedPasses: 30,
          image: null,
          heatMap: {
            x: 70,
            y: 50,
            intensity: 0.7,
          },
        },
        {
          playerNumber: 7,
          position: "MC",
          name: "Emiliano Martínez",
          score: 8,
          goals: 0,
          assists: 0,
          completedPasses: 20,
          image: null,
          heatMap: {
            x: 50,
            y: 30,
            intensity: 0.6,
          },
        },
      ],
    },
  },
};

export default mockSimulationResult;
