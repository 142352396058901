import React, { createContext, useContext } from 'react';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const CONFIG = {
    GENERIC_SHIELD: "https://firebasestorage.googleapis.com/v0/b/futbolsimulator-38530.appspot.com/o/icons%2Fgeneric-shield.png?alt=media&token=ba47b6e9-268a-404c-9623-803ef7927e83",
  };

  return (
    <ConfigContext.Provider value={CONFIG}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};
