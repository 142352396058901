
// /middleware/parseSimulationResult.js

export const parseSimulationResult = (response) => {
    try {
        if (!response.data) {
            throw new Error('La respuesta no tiene el formato esperado.');
        }
        // Return the response data directly since it's already a JSON object
        return response.data;
    } catch (error) {
        throw new Error('Failed to parse simulation result: ' + error.message);
    }
};
