// authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signInWithGoogle,
  signInWithEmail,
  registerWithEmail,
  resetPassword,
  checkUsername as checkUsernameService,
  signInWithTwitter,
} from '@/services/authService';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

const auth = getAuth();
const functions = getFunctions();

// Async thunk to check username availability
export const checkUsername = createAsyncThunk(
  'auth/checkUsername',
  async (username) => {
    try {
      return await checkUsernameService(username);
    } catch (error) {
      throw new Error(`Failed to check username: ${error.message}`);
    }
  }
);

// Async thunk for user login with Google
export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async (_, { dispatch }) => {
    const user = await signInWithGoogle();
    const userProfile = await fetchUserProfile(user.uid);
    const userData = createUserData(user, userProfile);
    dispatch(setUser(userData));
    return userData;
  }
);

// Async thunk for user login with email
export const loginWithEmail = createAsyncThunk(
  'auth/loginWithEmail',
  async ({ emailOrUsername, password }, { dispatch }) => {
    try {
      const user = await signInWithEmail(emailOrUsername, password);
      const userProfile = await fetchUserProfile(user.uid);
      const userData = createUserData(user, userProfile);
      dispatch(setUser(userData));
      return userData;
    } catch (error) {
      throw new Error(`Login failed: ${error.message}`);
    }
  }
);

// Async thunk for user registration
export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ email, password, username }, { dispatch }) => {
    const user = await registerWithEmail(email, password, username);
    const triggerUserUpdate = httpsCallable(functions, 'triggerUserUpdate');
    await triggerUserUpdate({ uid: user.uid, username, email });
    const userProfile = await fetchUserProfile(user.uid);
    const userData = createUserData(user, userProfile, username);
    dispatch(setUser(userData));
    return userData;
  }
);

// Async thunk for password reset
export const resetPasswordAction = createAsyncThunk(
  'auth/resetPassword',
  async (email, { dispatch }) => {
    await resetPassword(email);
    dispatch(clearError());
  }
);

// Async thunk for updating profile image
export const updateProfileImage = createAsyncThunk(
  'auth/updateProfileImage',
  async ({ uid, imageUrl }, { dispatch }) => {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { avatar: imageUrl });
    dispatch(updateAvatar(imageUrl));
    return imageUrl;
  }
);

// Async thunk for user logout
export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    await auth.signOut();
    dispatch(clearUser());
  }
);

// Async thunk for user login with Twitter
export const loginWithTwitter = createAsyncThunk(
  'auth/loginWithTwitter',
  async (_, { dispatch }) => {
    const user = await signInWithTwitter();
    const userProfile = await fetchUserProfile(user.uid);
    const userData = createUserData(user, userProfile);
    dispatch(setUser(userData));
    return userData;
  }
);

// Slice definition
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    error: null,
    usernameAvailability: {
      loading: false,
      available: null,
      error: null,
    },
  },
  reducers: {
    setUser(state, action) {
      const { teamRef, ...otherData } = action.payload;
      state.currentUser = { ...otherData, teamRef: teamRef || null };
    },
    clearUser(state) {
      state.currentUser = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    updateAvatar(state, action) {
      if (state.currentUser) {
        state.currentUser.avatar = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUsername.pending, (state) => {
        state.usernameAvailability.loading = true;
        state.usernameAvailability.error = null;
      })
      .addCase(checkUsername.fulfilled, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.available = action.payload;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.error = action.error.message;
      });
  },
});

// Helper function to create user data
const createUserData = (user, userProfile, displayName = null) => ({
  uid: user.uid,
  email: user.email,
  displayName: displayName || user.displayName,
  photoURL: user.photoURL,
  emailVerified: user.emailVerified,
  avatar: userProfile?.avatar || user.photoURL,
  points: userProfile?.points || 0,
  statistics: userProfile?.statistics || { lost: 0, played: 0, won: 0 },
  level: userProfile?.level || 1,
  teamRef: userProfile?.teamRef || null,
});

// Fetch user profile from Firestore
const fetchUserProfile = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    return userDoc.exists() ? userDoc.data() : null;
  } catch (error) {
    throw new Error(`Failed to fetch user profile: ${error.message}`);
  }
};

// Selectors
export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectError = (state) => state.auth.error;
export const selectUsernameAvailability = (state) => state.auth.usernameAvailability;

export const { setUser, clearUser, setError, clearError, updateAvatar } = authSlice.actions;

export default authSlice.reducer;

