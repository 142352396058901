
// @contexts/PlayerContext.js

import React, { createContext, useContext, useState, useCallback } from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
  const [players, setPlayers] = useState([]);

  const updatePlayerPosition = useCallback((playerId, newX, newY) => {
    setPlayers(prevPlayers =>
      prevPlayers.map(player =>
        player.id === playerId
          ? { ...player, vectorPosition: { ...player.vectorPosition, x: newX, y: newY } }
          : player
      )
    );
  }, []);

  return (
    <PlayerContext.Provider value={{ players, setPlayers, updatePlayerPosition }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = () => useContext(PlayerContext);
