export function calculatePlayerPositions(players, formation, isHost) {
    if ((!players || !players.length) || typeof formation !== 'string' || formation.trim().length === 0) {
      console.error('Invalid formation:', formation);
      return {};
    }
  
    const formationArray = formation.split("-").map(Number);
    const playerPositions = {};
  
    const goalkeeper = players.find((player) => player.role === "Goalkeeper" || player.role === "Portero");
    playerPositions[goalkeeper.id] = { x: 50, y: isHost ? 5 : 95 };
  
    let outfieldPlayers = players.filter((player) => player.role !== "Goalkeeper" && player.role !== "Portero");
  
    let currentY = isHost ? 20 + 3 : 80 - 0;
    const deltaY = (80 / formationArray.length) + 5;
  
    formationArray.forEach((numPlayers, index) => {
      let currentPlayerLine = outfieldPlayers.splice(0, numPlayers);
  
      const startX = (100 - (100 / (numPlayers + 1)) * numPlayers) / 2;
      const deltaX = numPlayers > 1 ? (100 - startX * 2) / (numPlayers - 1) : 0;
  
      currentPlayerLine.forEach((player, playerIndex) => {
        const x = numPlayers > 1 ? startX + deltaX * playerIndex : 50;
        const y = isHost ? currentY + (deltaY * index) : currentY - (deltaY * index);
        playerPositions[player.id] = { x, y };
      });
    });
  
    return playerPositions;
  }
  