
// jerseySlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentCombinationIndex: 0,
  jerseyCombinations: [
    {
      shirtText: "Emirates",
      shirtColor: "#f00000",
      sleeveColor: "#fff",
      shirtStyle: "checkered",
      shirtStyleColor: "#dc0001",
      textColor: "#fff",
    },
    {
      shirtText: "CAZOO",
      shirtColor: "#670e36",
      sleeveColor: "#94bee5",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "AMERICAN",
      shirtColor: "#398ae7",
      sleeveColor: "#398ae7",
      shirtStyle: "striped-thin",
      shirtStyleColor: "#fff",
      textColor: "#fff",
    },
    {
      shirtText: " #|# ",
      shirtColor: "#81204c",
      sleeveColor: "#8bd3f5",
      textColor: "#fff",
    },
    {
      shirtText: "3",
      shirtColor: "#001489",
      sleeveColor: "#001489",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "W88",
      shirtColor: "#014a97",
      sleeveColor: "#014a97",
      shirtStyle: "striped",
      shirtStyleColor: "#ff362b",
      textColor: "#fff",
    },
    {
      shirtText: "BETVICTOR",
      shirtColor: "#fff",
      sleeveColor: "#333",
      shirtStyle: "plain",
      textColor: "#222",
    },
    {
      shirtText: "CAZOO",
      shirtColor: "#00318e",
      sleeveColor: "#00318e",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "20",
      shirtColor: "#000000",
      sleeveColor: "#ffcc00",
      shirtStyle: "hoops",
      shirtStyleDirection: "horizontal",
      shirtStyleColor: "#ffcc00",
      textColor: "#ffffff",
    },
    {
      shirtText: "SBOTOP",
      shirtColor: "#ffffff",
      sleeveColor: "#ffffff",
      shirtStyle: "plain",
      textColor: "#1d4189",
    },
    {
      shirtText: "THAILAND",
      shirtColor: "#003090",
      sleeveColor: "#003090",
      shirtStyle: "plain",
      textColor: "#fefefe",
    },
    {
      shirtText: "Standard &",
      shirtColor: "#e31b23",
      sleeveColor: "#e31b23",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "ETIHAD",
      shirtColor: "#98c5e9",
      sleeveColor: "#98c5e9",
      shirtStyle: "plain",
      textColor: "#00285e",
    },
    {
      shirtText: " CHEVROLET ",
      shirtColor: "#c70101",
      sleeveColor: "#c70101",
      shirtStyle: "plain",
      textColor: "#fed500",
    },
    {
      shirtText: "FUN88",
      shirtColor: "#fff",
      sleeveColor: "#333",
      shirtStyle: "striped",
      shirtStyleColor: "#111",
      textColor: "#00bcf6",
    },
    {
      shirtText: "## USG",
      shirtColor: "#fff",
      sleeveColor: "#fff",
      shirtStyle: "striped-thick",
      shirtStyleColor: "#e30613",
      textColor: "#111",
    },
    {
      shirtText: "Pepsi",
      shirtColor: "#163ba3",
      sleeveColor: "#163ba3",
      shirtStyle: "single-band",
      shirtStyleDirection: "horizontal",
      shirtStyleColor: "#f4ce00",
      textColor: "#163ba3",
    },
    {
      shirtText: "PETROBRAS",
      shirtColor: "#00aae4",
      sleeveColor: "#00aae4",
      shirtStyle: "striped-thick",
      shirtStyleColor: "#fff",
      textColor: "#fff",
      textBackgroundColor: "#004173",
      shirtStyleDirection: "vertical",
    },
    {
      shirtText: "BBVA",
      shirtColor: "#fff",
      sleeveColor: "#fff",
      shirtStyle: "single-band",
      shirtStyleColor: "#ff0028",
      textColor: "#111",
      shirtStyleDirection: "diagonal-left",
    },
    {
      shirtText: "AIA",
      shirtColor: "#fff",
      sleeveColor: "#0a225c",
      shirtStyle: "plain",
      textColor: "#d31145",
    },
    {
      shirtText: "ideal",
      shirtColor: "#fff",
      sleeveColor: "#002f68",
      shirtStyle: "striped",
      shirtStyleColor: "#002f68",
      textColor: "#fff",
      textBackgroundColor: "#002f68",
    },
    {
      shirtText: "betWay",
      shirtColor: "#7d2b3a",
      sleeveColor: "#59b3e4",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "FiT|#",
      shirtColor: "#fdb913",
      sleeveColor: "#333",
      shirtStyle: "plain",
      textColor: "#222",
    },
    {
      shirtText: " All ",
      shirtColor: "#00234a",
      sleeveColor: "#00234a",
      shirtStyle: "single-band",
      shirtStyleColor: "#cc1339",
      textColor: "#fff",
      shirtStyleDirection: "vertical",
    },
    {
      shirtText: "Emirates",
      shirtColor: "#df061b",
      sleeveColor: "#444",
      shirtStyle: "striped",
      shirtStyleColor: "#222",
      textColor: "#fff",
    },
    {
      shirtText: "Rakuten",
      shirtColor: "#081868",
      sleeveColor: "#081868",
      shirtStyle: "striped-thick",
      shirtStyleColor: "#84112b",
      textColor: "#fff",
    },
    {
      shirtText: "-T--- ",
      shirtColor: "#d1122e",
      sleeveColor: "#d1122e",
      shirtStyle: "plain",
      textColor: "#fff",
    },
    {
      shirtText: "Jeep",
      shirtColor: "#fff",
      sleeveColor: "#fff",
      shirtStyle: "striped",
      shirtStyleColor: "#222",
      textColor: "#e1ceaf",
    },
    {
      shirtText: " 1&1 ",
      shirtColor: "#f6ea32",
      sleeveColor: "#f6ea32",
      shirtStyle: "dashed",
      shirtStyleColor: "#222",
      shirtStyleDirection: "diagonal-right",
      textColor: "#fff",
      textBackgroundColor: "#333",
    },
    {
      shirtText: "+ Inchusa",
      shirtColor: "#e4343b",
      sleeveColor: "#fff",
      shirtStyle: "two-color",
      shirtStyleColor: "#1d2c47",
      textColor: "#fff",
    },
    {
      shirtText: " FEDCOM ",
      shirtColor: "#fff",
      sleeveColor: "#fff",
      shirtStyle: "two-color",
      shirtStyleColor: "#e53236",
      shirtStyleDirection: "diagonal-left",
      textColor: "#fff",
    },
    {
      shirtText: "PIRELI",
      shirtColor: "#1756ca",
      sleeveColor: "#1756ca",
      shirtStyle: "waves",
      shirtStyleColor: "#222",
      textColor: "#fff",
    },
  ],
};

export const jerseySlice = createSlice({
  name: "jersey",
  initialState,
  reducers: {
    nextCombination: (state) => {
      state.currentCombinationIndex =
        state.currentCombinationIndex < state.jerseyCombinations.length - 1
          ? state.currentCombinationIndex + 1
          : 0;
    },
    prevCombination: (state) => {
      state.currentCombinationIndex =
        state.currentCombinationIndex > 0
          ? state.currentCombinationIndex - 1
          : state.jerseyCombinations.length - 1;
    },
    setColor: (state, action) => {
      const { index, color } = action.payload;
      const combination =
        state.jerseyCombinations[state.currentCombinationIndex];
      if (index === 0) combination.shirtColor = color;
      if (index === 1) combination.sleeveColor = color;
      if (index === 2) combination.shirtStyleColor = color;
      if (index === 3) combination.textColor = color;
    },
    setJerseyConfiguration: (state, action) => {
      const matchIndex = state.jerseyCombinations.findIndex(
        (combination) =>
          JSON.stringify(combination) === JSON.stringify(action.payload)
      );
      if (matchIndex !== -1) {
        state.currentCombinationIndex = matchIndex;
      } else {
        state.jerseyCombinations.push(action.payload);
        state.currentCombinationIndex = state.jerseyCombinations.length - 1;
      }
    },
  },
});

export const { nextCombination, prevCombination, setColor, setJerseyConfiguration } =
  jerseySlice.actions;

export default jerseySlice.reducer;